<script setup lang="ts">
import { TRACKING_EVENT_TYPES } from '@yescapa-dev/ysc-api-js/modern'
import { YscApiError } from '~/utils/error/YscApiError'
import { YSC_API_CAMPER_ERROR, YSC_API_VEHICLE_ERROR } from '~/utils/error/YscErrorClasses'

definePageMeta({
  middleware: defineNuxtRouteMiddleware(async (to, from) => {
    const camperId = paramValueToNumber(to.params.id)
    if (!camperId) {
      throw createError({ statusCode: 404, message: 'Invalid camper id' })
    }

    const nuxtApp = useNuxtApp()
    const { fetchCamper, fetchVehicle, fetchCurrentCamperAvailabilities } = useCamperStore()
    const { camper, currentCamperAvailabilities: availabilities } = storeToRefs(useCamperStore())

    try {
      await fetchCamper({ id: camperId, params: { user_action: TRACKING_EVENT_TYPES.CAMPER_VIEWED } })
    }
    catch (e) {
      let qualifiedError = e
      let statusCode = 404
      if (e instanceof Error) {
        qualifiedError = await nuxtApp.$errorManager({ e, name: YSC_API_CAMPER_ERROR })
        if (qualifiedError instanceof YscApiError && qualifiedError.apiStatus === 410) {
          statusCode = 410
        }
      }
      throw createError({ statusCode, cause: qualifiedError, message: 'Error fetching camper' })
    }

    const [vehicleRequest, availabilitiesRequest] = await Promise.allSettled([
      fetchVehicle(),
      fetchCurrentCamperAvailabilities(),
    ])

    if (vehicleRequest.status === 'rejected') {
      await nuxtApp.$errorManager({ e: vehicleRequest.reason, name: YSC_API_VEHICLE_ERROR })
      throw createError({ statusCode: 404, cause: vehicleRequest.reason, message: 'Error fetching camper' })
    }

    if (availabilitiesRequest.status === 'rejected') {
      // If the camper is not bookable ( deleted or lack of documents for example ) availabilities return a 404 error but we don't want it to block the page or send to sentry.
      await nuxtApp.$errorManager({ e: availabilitiesRequest.reason, name: YSC_API_CAMPER_ERROR })
      throw createError({ statusCode: 404, cause: availabilitiesRequest.reason, message: 'Error fetching camper availabilities' })
    }

    if (!camper.value) {
      // If camper is null here there is a problem with the store, it should have thrown on fetchCamper.
      throw createError({ statusCode: 500, message: 'Runtime error - no camper in store' })
    }

    const { query } = to

    if (!Object.keys(query).length) {
      return
    }

    if (import.meta.client && from.name === 'campers-id' && ((!from.query.more && to.query.more) || (from.query.more && !to.query.more))) {
      return
    }

    const { mustRedirect, nextQuery } = await validateCamperQuery({
      query,
      availabilities: availabilities.value,
      camper: camper.value,
    })

    if (mustRedirect) {
      return nuxtApp.runWithContext(() => navigateTo({ name: 'campers-id', params: to.params, query: nextQuery }))
    }
  }),
})
</script>

<template>
  <div>
    <NuxtPage />
  </div>
</template>
